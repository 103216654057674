.prescription-details{
    max-height: 440px;
    width: 270px;
    background-color: #fff;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px #0008;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 10px;
    overflow: scroll;
    // justify-content: space-evenly;
    
    div{
        margin: 2.5px 0;
        span{
            color: #000c;
            margin-left: 5px;
        }
    }
}