.ovCardWrapper{
    height: 150px;
    /* width: 300px; */
    background-color: #fce2e5;
    border-radius: 10px;
    box-shadow: 0 1px 3px #3f383955;
    margin: 40px 60px 40px 50px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    min-width: 200px;
    width: 22%;
    max-width: 300px;
    
    .innerWrapper{
        flex-grow: 1;
        text-align: center;
        background-color: #fff2f200;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgba(51, 44, 68, 0.129);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: #5f3c3c;
        text-shadow: 1px 1px 4px #612b7d50;
        padding: 0 10px;
        
        *{
            margin: 7px 0;
        }
        
        .cardIcon{
            
            box-shadow: 0px 0px 2px rgba(33, 3, 102, 0.2);
            text-shadow: 1px 1px 4px #612b7dad;
            font-size: 25px;
            border-radius: 5px;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .title{
            // margin-left: ;
            // width: 50%;
        }
        
        .counter{
            // width: 80%;
        }
    }
}