.sign-up {
  display: flex;
  flex-direction: column;
  // max-width: 50%;
  min-width: 30%;
  margin-bottom: 0 0 5px 5px;

  .title {
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }
  .dologin{
    margin-top: 20px;
    margin-bottom: -10px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: #855f;
}
}
