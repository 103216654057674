.rxlist {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    flex-grow: 1;

    .listHead {
        .title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 30px;
        }
        margin-bottom: 10px;
    }
    
    .rxfilter{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 10px 0;
        padding: 5px 0;
        // box-shadow: 0 0 3px #0005;
        justify-content: space-evenly;
        align-items: center;
        
        .rxfilter-sec{
            // flex-grow: 1;
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &:nth-child(2){
                font-size: large;
            }
        }
        
        .rxfilter-sec2{
            flex-grow: 2;
            font-size: 15px;
        }
    }

    .allLists {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // flex-grow: 1;
        // // background-color: aliceblue;
        overflow: scroll;
        justify-content: center;
    }

    #react-paginate {
        display: flex;
        flex-direction: row-reverse;
        padding: 10px 60px;
        ul {
            display: inline-block;
            padding-left: 15px;
            padding-right: 15px;
        }

        li {
            background: white;
            padding: 10px 5px;
            /* margin: 5px; */
            border-style: solid;
            border-width: 1px;
            border-color: #519abd;
            cursor: pointer;

            display: inline-block;
            
            a{
                padding: 0 15px;
            }
        }
        .active{
            color: white;
            background-color: #519abd;
        }
        
        .previous{
            margin-right: 5px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .next{
            margin-left: 5px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}
