.drawerContainer{
    // flex-grow: 1;
    width: 200px;
    height: 100%;
    box-shadow: 0 1px 2px rgba(29, 19, 116, 0.25);
    
    .drawerHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
        color: rgb(67, 3, 150);
        font-weight: 600;
        font-size: 18px;
        height: 50px;
        
        .drawericon{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            width: 40px;
        }
    }
    
    .menus{
        font-size: 15px;
        padding: 5px 5px 20px 5px;
        color: rgba(38, 10, 83, 0.8);
        
        .menuItem{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 45px;
            margin: 5px 0;
            padding: 0 20px;
            box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            &:hover{
                color: #921463;
                font-size: 16px;
            }
        }
    }
}