.sign-in{
    display: flex;
    flex-direction: column;
    // max-width: 50%;
    min-width: 35%;
    margin: 0 5px 20px 0;
    
    .title{
        margin:10px 0;
        display: flex;
        justify-content: center;
    }
    
    .button-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .createacc{
        margin-top: 20px;
        margin-bottom: -10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        color: #855f;
    }
}