.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    background-color: white;
    padding: 5px 0px;
    box-shadow: 0 1px 3px rgba(31, 5, 92, 0.356);
    width: 100%;
    
    .header-logo{
        height: 100%;
        margin-left: 15px;
        .logo-wrapper{
            height: 100%;
            width: 55px;
            object-fit: cover;
            img{
                height: 100%;
                width: 100%;
            }
        }
    }

    .header-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin-right: 40px;

        .header-icon {
            border-color: #0002;
            border-width: 1px;
            border-style: solid;
            border-radius: 4px;
            padding: 3px;
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .header-menu-popup {
        position: absolute;
        top: 61px;
        right: 0;
        // width: 160px;
        /* height: 100px; */
        display: flex;
        flex-direction: column;
        padding: 10px 65px;
        /* border-style: solid; */
        border-width: 1px;
        /* border-color: #0000 #0005 #0005 #0005; */
        border-radius: 15px 0px 15px 15px;
        box-shadow: 0px 1px 5px #0004;
        font-size: 14px;
        background-color: #fff;
        
        .header-menu-item{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 5px 0;
            cursor: pointer;
            color: #000c;
            
            span{
                display: flex;
                justify-content: center;
            }
        }
    }
}
