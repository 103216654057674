.custom-button {
  min-width: 165px;
  width: auto;
  max-height: 6.5vw;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  margin: 2px 0;
  font-size: 15px;
  background-color: #2c4258;
  color: white;
  text-transform: uppercase;
  font-family: "Open Sans Condensed";
  font-weight: bolder;
  border: 2px solid #2c4258;
  border-radius: 0 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: white;
    color: #2c4258;
    border: 2px solid #2c4258;
  }

  &.google-signin {
    background-color: #456585;
    border: 2px solid #456585;
    &:hover {
      background-color: #e5f0fd;
    }
  }

  &.inverted {
    background-color: white;
    color: #2c4258;
    border: 2px solid #2c4258;
    &:hover {
      background-color: #2c4258;
      color: white;
      border: 2px solid #021e39;
    }
  }
}
