.docCard {
    min-height: 180px;
    min-width: 450px;
    max-height: 180px;
    max-width: 450px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-color: rgba(73, 49, 117, 0.35);
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    font-size: 12px;
    box-shadow: 0 3px 5px rgba(82, 82, 121, 0.4);
    margin: 20px;
    
    .frstSec {
        display: inline-flex;
        flex-direction: row;
        height: 80px;
        margin: 2px;
        .imgWrapper {
            width: 70px;
            height: 80px;
            padding: 3px;
            border-color: rgba(21, 35, 82, 0.329);
            border-width: 1px;
            border-style: solid;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .details {
            margin-left: 10px;
            padding: 10px 0;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: space-evenly;
            div {
                // color: #0a609e;
                color: #000c;
                margin: 2px 0;
                div {
                    font-weight: normal;
                    margin-left: 5px;
                    color: #000;
                }
            }

            .items {
                display: flex;
                flex-direction: row;
            }
        }
    }
    .scndSec {
        padding: 5px;
        margin: 0px 2px 2px 2px;
        // margin-top: 10px;
        .items {
            display: flex;
            flex-direction: row;
            // color: #0a609e;
            color: #000c;
            font-weight: bold;
            margin: 1px 0;
            div,
            span {
                font-weight: normal;
                margin-left: 10px;
                color: #000;
                font-size: 12px;
            }
        }
    }
    .modal {
        position: fixed;
        background-color: #fff;
        top: 20%;
        left: 25%;
        padding: 100px 0;

        border-style: solid;
        border-color: #5555;
        border-radius: 10px;

        .closeBtn {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
