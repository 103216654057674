.authentication {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    
    .headTitle{
        margin: 50px 0 20px 0px;
        padding: 20px;
        font-size: 30px;
    }
    
    .wrapper {
        padding: 30px;
        border-style: solid;
        border-color: #5555;
        border-radius: 20px;
        margin: 0px 50px 30px 50px;
        min-width: 30%;
        
        .changepass{
            display: flex;
            flex-direction: column;
            // max-width: 50%;
            min-width: 35%;
            margin: 0 5px 20px 0;
            
            .title{
                margin:10px 0;
                display: flex;
                justify-content: center;
            }
            
            .button-box{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .dashback{
                margin: 10px 0;
                font-size: 14px;
                display: flex;
                justify-content: center;
                cursor: pointer;
                color: #855f;
            }
        }
    }
    
    

}