.ptnlist {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;

    .listHead {
        .title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 30px;
        }
        margin-bottom: 10px;
    }

    .allLists {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // flex-grow: 1;
        // background-color: aliceblue;
        overflow: scroll;
        justify-content: center;
    }

    
}
