body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body, #root, .App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  /* overflow: hidden; */
}

::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}

::-webkit-scrollbar-thumb {
  background: #FF0000;
}
