.rxCard {
    min-height: 180px;
    width: 450px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-color: rgba(73, 49, 117, 0.35);
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    font-size: 12.5px;
    box-shadow: 0 3px 5px rgba(82, 82, 121, 0.4);
    margin: 15px 20px;
    justify-content: space-between;

    .rxfrstSec {
        display: inline-flex;
        flex-direction: row;
        // min-height: 126px;
        .imgWrapper {
            min-width: 90px;
            height: 118px;
            padding: 3px;
            border-color: rgba(21, 35, 82, 0.329);
            border-width: 1px;
            border-style: solid;
            border-radius: 5px;
            cursor: pointer;
            img {
                height: 100%;
                width: 100%;
                object-fit: fill;
            }
        }
        .details {
            margin-left: 10px;
            padding: 5px 0;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: space-evenly;
            div {
                // color: #0a609e;
                font-weight: bold;
                div,
                span {
                    margin-left: 5px;
                    color: #000;
                    font-weight: normal;
                }
            }
        }
    }
    .scndSec {
        padding: 6px 8px;
        .advice {
            // color: #0a609e;
            font-weight: bold;
            div,
            span {
                margin-left: 5px;
                color: #000;
                font-weight: normal;
            }
        }
        .footer {
            display: flex;
            flex-direction: row;
            // color: #0a609e;
            margin-top: 4px;
            justify-content: space-between;
            .token-id{
                font-weight: bold;
                font-size: 13px;
                color: #0a609e;
                cursor: pointer;
            }
        }
    }
    .modal {
        position: fixed;
        /* background-color: #251f1f61; */
        top: 0;
        left: 0;
        padding: 100px 0;
        border-style: solid;
        border-color: #5555;
        // border-radius: 10px;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 10;

        .modalWrapper {
            background-color: white;
            height: fit-content;
            transform: scale(1.5);
            border-radius: 10px;
            border-width: 1px;
            border-color: #0005;
            border-style: solid;
            box-shadow: 1px 2px 4px #0004;
            margin-top: 90px;
            
            .closeBtn {
                position: absolute;
                top: 5px;
                right: 5px;
                width: 10px;
                height: 10px;
                display: flex;
                padding: 2px;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                border-style: solid;
                border-color: #0003;
                border-width: 1px;
                cursor: pointer;
                color: #000a;
            }
            
        }
    }
}
